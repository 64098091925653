<template>
  <div v-if="modelValue">
    <!-- <el-dialog :model-value="modelValue" :before-close="handleClose" :title="title" append-to-body custom-class="footer_border_dialog" width="560px"> -->
    <div class="model_title">
      {{ title }}
    </div>
    <div class="change_phone_box">
      <el-form
        ref="form"
        :model="map"
        :rules="opts.rules"
        size="large"
      >
        <el-form-item prop="phone">
          <div class="prefix_select_input">
            <el-input
              v-model="map.phone"
              placeholder="请输入手机号"
            >
              <template #prefix>
                <div
                  v-if="popover.visible"
                  class="popover_mask"
                  @click="closePopover"
                />
                <client-only>
                  <el-popover
                    v-model:visible="popover.visible"
                    :teleported="false"
                    :width="380"
                    placement="bottom-start"
                    trigger="click"
                  >
                    <template #reference>
                      <span
                        class="c_333 pointer pop_holder pop_border"
                        @click="openPopver('telCode')"
                      >
                        <span class="ellipsis">+{{ map.telCode }}</span>
                        <i class="arrows-bottom" />
                      </span>
                    </template>
                    <el-scrollbar>
                      <div class="te_code_enum">
                        <div
                          v-for="item in opts.TelCodeEnum"
                          :key="item.standardDesc"
                          class="te_code_enum_item"
                          @click="handleSelectTelCode(item, 'telCode')"
                        >
                          <span>
                            <span class="mgr10"> {{ item.codeDesc }}</span>
                            <span>{{ item.standardDesc }}</span>
                          </span>
                          <i
                            v-if="item.code + item.standardDesc === map.telCode + opts.newCountry"
                            class="iconfont icon-correct blue font12"
                          />
                        </div>
                      </div>
                    </el-scrollbar>
                  </el-popover>
                </client-only>
              </template>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item prop="verificationCode">
          <el-input
            v-model="map.verificationCode"
            placeholder="短信验证码"
          >
            <template #prefix>
              <span class="iconfont icon-comment" />
            </template>
            <template #suffix>
              <el-button
                class="c_999"
                type="text"
                @click="getCode"
              >
                {{ txt }}
              </el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          v-if="!isExist"
          prop="loginPwd"
        >
          <el-input
            v-model="map.loginPwd"
            type="password"
            show-password
            placeholder="密码：8-20，同时包含大小写字母和数字"
          />
        </el-form-item>
      </el-form>
    </div>
    <!-- <template #footer>
      <div class="footer_dialog">
        <el-button plain @click="handleClose">取消</el-button> -->
    <el-button
      class="submit"
      :loading="ctrl.loading"
      type="primary"
      @click="handleSubmit"
    >
      完成
    </el-button>
  </div>
  <!-- </template>
  </el-dialog> -->
</template>

<script setup>
import { reactive, ref, watch } from 'vue'
import { useUserStore } from '../../stores/user'
import { getMsg } from '../../api/user'
import { ElMessage } from 'element-plus'
import { wxBinding, checkPhoneExist } from '@/api/system'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  userInfo: {
    type: Object,
    default: function () {
      return {}
    }
  }
})

const emit = defineEmits(['update:modelValue', 'callback'])
const form = ref(null)
const validatePhone = async (rule, value, callback) => {
  // 验证当前绑定手机号
  if (!map.telCode) {
    callback(new Error('请输入选择区号'))
  } else if (!value) {
    callback(new Error('请输入手机号'))
  } else {
    callback()
  }
}

const validateCode = (rule, value, callback) => {
  // 验证验证码
  if (!value) {
    callback(new Error('请输入验证码'))
  } else {
    callback()
  }
}
const checkPwd = (rule, value, callback) => {
  const passwordreg =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/
  if (!value) {
    return callback(new Error('请输入新的登录密码'))
  }
  if (!passwordreg.test(value) || value.length > 20 || value.length < 8) {
    callback(new Error('密码必须由8-20位大写和小写字母和数字组成'))
  } else {
    callback()
  }
}
const opts = reactive({
  title: '',
  temp: 2,
  TelCodeEnum: [],
  country: '中国',
  newCountry: '中国',
  rules: {
    phone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
    verificationCode: [{ required: true, validator: validateCode, trigger: 'blur' }],
    loginPwd: [
      {
        required: true,
        validator: checkPwd,
        trigger: 'blur'
      }
    ]
  },
  timer: null
})
const { getEnumList, setToken, shopData } = useUserStore()
const map = reactive({
  phone: '',
  telCode: 86,
  verificationCode: '',
  loginPwd: ''
})
const ctrl = reactive({
  loading: false
})
const popover = reactive({
  visible: false
})
const isExist = ref(true) // 用户是否存在
const isDisabled = ref(false) // 用户是否禁用
onMounted(() => {
  getEnumList('TelCodeEnum', 'arr').then((res) => {
    opts.TelCodeEnum = res
  })
})
watch(
  () => props.modelValue,
  (val) => {
    if (val) {
      // 初始值设置
    } else {
      // 清除表单验证和定时器
      if (opts.timer) {
        clearInterval(opts.timer)
        opts.timer = null
      }
      if (form.value) {
        form.value.resetFields()
        map.telCode = 86
      }
    }
  }
)
const txt = ref('获取验证码')
const disabled = ref(false)
const timeOut = () => {
  // 验证码倒计时
  let num = 60
  disabled.value = true
  txt.value = num
  opts.timer = setInterval(() => {
    if (num <= 0) {
      clearInterval(opts.timer)
      opts.timer = null
      disabled.value = false
      txt.value = '重新获取'
    } else {
      num--
      txt.value = num + 's'
    }
  }, 1000)
}
const getCode = async () => {
  // 获取验证码
  if (disabled.value) return
  if (!/^1\d{10,10}$/.test(parseInt(map.phone))) {
    ElMessage.error('请输入正确的手机号')
    return false
  }
  const checkRes = await checkPhoneExist({ phone: map.phone, telCode: map.telCode })
  if (checkRes) {
    isExist.value = checkRes.userExist
    isDisabled.value = checkRes.disabled
    // 用户存在且状态正常， 用户不存在且平台允许注册，可获取验证码
    if (isExist.value && isDisabled.value) {
      ElMessage.success('用户已被禁用，不可绑定')
    } else if (!isExist.value && shopData && !shopData.config.allowUserRegister) {
      ElMessage.success('平台用户人数已达上限，暂不可注册')
    } else {
      getMsg({
        phone: map.phone,
        telCode: map.telCode,
        verificationCodeType: 4
      }).then((res) => {
        if (res) {
          ElMessage.success('短信已发送，请注意查收！')
          timeOut()
        }
      })
    }
  }
}
const closePopover = () => {
  popover.visible = false
}
const openPopver = (key) => {
  // 打开选择区号
  const activeIndex = opts.TelCodeEnum.findIndex((item) => {
    return item.code + item.standardDesc === map[key || 'telCode'] + opts[key ? 'newCountry' : 'country']
  })
  const activeItem = opts.TelCodeEnum.splice(activeIndex, 1)
  opts.TelCodeEnum.unshift(activeItem[0])
  popover.visible = true
}
const handleSelectTelCode = (row, key) => {
  // 选择区号
  map[key || 'telCode'] = row.code
  opts[key ? 'newCountry' : 'country'] = row.standardDesc
  closePopover()
}
const handleSubmit = () => {
  // 绑定新手机
  form.value.validate((valid) => {
    if (valid) {
      // 用户存在且状态正常或用户不存在且平台允许注册，可绑定微信或注册并绑定微信
      if (isExist.value && isDisabled.value) {
        ElMessage.success('用户已被禁用，不可绑定')
        return false
      } else if (!isExist.value && shopData && !shopData.config.allowUserRegister) {
        ElMessage.success('平台用户人数已达上限，暂不可注册')
        return false
      } else {
        // ctrl.loading = true
        wxBinding({ ...map, ...props.userInfo, clientType: 1 })
          .then((res) => {
            // ctrl.loading = false
            if (res) {
              setToken(res.token)
              ElMessage.success('绑定手机成功')
              emit('callback')
              handleClose()
            }
          })
          .catch(() => {
            ctrl.loading = false
          })
      }
    }
  })
}
const handleClose = () => {
  // 关闭
  emit('update:modelValue', false)
}
</script>

<style lang="scss" scoped>
.model_title {
  padding: 30px 0;
  font-size: 16px;
  line-height: 20px;
}
.change_tip {
  margin-bottom: 20px;
  margin-left: 45px;
}

.footer_dialog {
  text-align: center;

  .el-button {
    width: 80px;
    height: 32px;
    border-radius: 4px;
  }
}
.submit {
  width: 100%;
  height: 50px;
  margin-top: 14px;
  font-size: 16px;
}
</style>
